<template>
  <div id="app">
    <keep-alive :include="keepAliveList">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keepAliveList: []
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.meta.keepAlive && this.keepAliveList.indexOf(val.name) == -1) {
          this.keepAliveList.push(val.name)
        }
        if (oldVal.meta.keepAlive) {
          // 控制旧页面是否刷新
          if (oldVal.meta.isRefresh === 1 && val.meta.fromRefresh !== 0) {
            this.keepAliveList = this.keepAliveList.filter(item => {
              return item !== oldVal.name
            })
          }
        }
        if (!oldVal.meta.keepAlive) {
          this.keepAliveList = this.keepAliveList.filter(item => {
            return item !== oldVal.name
          })
        } else {
          this.keepAliveList.push(val.name)
        }
        console.log(this.keepAliveList)
      },
      // 深度观察监听
      deep: true
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
