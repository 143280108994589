import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/index.scss'
import Vant from 'vant';
import 'vant/lib/index.css';
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import httpAppRequest from '@/utils/httpRequest-app'
import httpCustAppRequest from '@/utils/httpRequest-custApp'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies'
import FormMaking from '@/formMaking/src/index.js'
import 'form-making/dist/FormMaking.css'
Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.use(FormMaking)
// 高德离线地图
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德key
  key: '34ce1d182accab3617699fbf2f28d72a',
  // 插件集合 （插件按需引入）
  // plugin: ['AMap.Geolocation']
  plugin: ['AMap.Geocoder', 'AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor']
})

// const sUserAgent = window.navigator.userAgent.toLowerCase()
// const bIsDtDreamApp = sUserAgent.indexOf('dtdreamweb') > -1 // 浙里办APP
// const bIsAlipayMini =     sUserAgent.indexOf('miniprogram') > -1 && sUserAgent.indexOf('alipay') > -1
// if (!obj.ticket) {
// if (bIsDtDreamApp) {
//   console.log('------------app----------------');
//    window.location.href = 'https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=lbt'
// }
// if (bIsAlipayMini) {
//   console.log('---------------小程序---------------');
//    window.location.href = 'https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&servicecode=lbt'
// }
// }

Vue.prototype.$orgId = ''
Vue.prototype.$userId = ''
Vue.prototype.$userName = '管理员'
Vue.prototype.$orgName = '未知社区'
Vue.prototype.$code = ''
Vue.prototype.$appId = ''
Vue.prototype.$location = {
  latitude: '',
  longitude: ''
}
Vue.prototype.$globalData = {
  limit: 10,
  userInfo : {
    userId: 0,
    street: '',
    communityName: '',
    houseId: 0,
  }
}
//全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive('real-img', async function (el, binding) {//指令名称为：real-img
  let imgURL = binding.value;//获取图片地址
  if (imgURL) {
    let exist = await imageIsExist(imgURL);
    if (exist) {
      el.setAttribute('src', imgURL);
    }
  }
})
/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function(url) {
  return new Promise((resolve) => {
    var img = new Image();
    img.onload = function () {
      if (this.complete == true){
        resolve(true);
        img = null;
      }
    }
    img.onerror = function () {
      resolve(false);
      img = null;
    }
    img.src = url;
  })
}
Vue.prototype.$topBarShow = true
Vue.prototype.$orgList = []
Vue.prototype.$baseURL = ''
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$httpApp = httpAppRequest
Vue.prototype.$httpCustApp = httpCustAppRequest
Vue.use(Vant);

Vue.config.productionTip = false
console.log(process.env);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
