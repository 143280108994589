import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    noticeId: '',
    guideId: 1,
    problemId: 1,
    problemData: {},
    nationality: 0,
    nation: 0,
    GripData: {},
    reminderData: {},
    casualTakingPhotos: 0,
    returnData: {},
    repairData: {}
  },
  mutations: {
    setNoticeId(state,id) {
      state.noticeId = id || ''
    },
    setGuideId(state,id) {
      state.guideId = id || 1
    },
    setProblemId(state,id) {
      state.problemId = id || 1
    },
    setNationality(state,id) {
      state.nationality = id || 0
    },
    setNation(state,id) {
      state.nation = id || 0
    },
    setProblemData(state,data) {
      state.problemData = data || {}
    },
    setGripData(state,data) {
      state.gripData = data || {}
    },
    setReminderData(state,data) {
      state.reminderData = data || {}
    },
    setCasualTakingPhotos (state, data) {
      state.casualTakingPhotos = data || 0
    },
    setReturnData (state, data) {
      state.returnData = data || 0
    },
    setHouseId(state,id) {
      state.houseId = id || 0
    },
    setMigrantNum(state,num) {
      state.migrantNum = num || 0
    },
    setRepairData(state,data) {
      state.repairData = data || {}
    }
  },
  actions: {
  },
  modules: {
  }
})
